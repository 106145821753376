import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './nav.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faDiscord} from '@fortawesome/free-brands-svg-icons'
import {Twitter} from './link.json'
import {Discord} from './link.json'
import {Mail_Page} from './link.json'



function Navigation() {
  return (
    <section className='Nav'>
        <div className="Navbar">
          <ul className='Menu'>


            <li className='Menu-item'><a target={'_blank'} href={Twitter}>
              
            <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" class="svg-inline--fa fa-twitter " 
            role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><path fill="currentColor" 
            d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></svg>
              </a></li>
            <li className='Menu-item'><a target={'_blank'} href={Discord}>
            <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="discord" class="svg-inline--fa fa-discord " 
            role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 256"><path fill="currentColor" d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"></path></svg>
              </a></li>
            <li className='Menu-item'><a target={'_blank'} href="https://opensea.io/collection/the-nft-yearbook-mintpass">
            <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="opensea" class="svg-inline--fa fa-discord " role="img" xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 320 256"><path fill="currentColor" 
            d="m 287.73922,25.857848 c -10.34088,-0.0799 -20.65953,6.55707 -22.90971,21.72426 l 1.17047,30.42553 -58.3644,-16.70337 c -6.03146,-0.90204 -11.09619,-1.17153 -5.14077,5.77225 75.64657,148.281012 52.66479,200.133582 0.90485,271.581882 -0.46899,3.0775 -6.80831,6.82768 5.78518,8.40828 l 55.42866,-1.1064 -0.32091,48.16037 -54.08766,0.0413 c -26.41643,-1.31506 -44.50554,-9.59332 -48.17483,-41.5649 1.30273,-7.02785 -2.75198,-10.3705 -11.513,-10.47533 L 42.619819,340.9363 c -5.88264,-0.27424 -11.27019,0.22142 -8.91884,12.75736 4.94624,66.19926 51.323549,133.18634 145.115171,134.83466 l 203.79893,1.03353 c 35.49488,1.33765 63.11035,-23.60718 86.5234,-62.56982 18.72609,-26.95984 24.66634,-58.76612 72.21265,-72.08862 5.78912,-2.53703 6.13885,-6.32861 6.11435,-9.55239 l -0.2589,-18.33325 c 1.86774,-9.5086 -3.57395,-9.07215 -9.79423,-7.57628 l -121.92899,35.40507 c -33.5707,34.70291 -40.64678,42.29409 -104.17039,38.70668 l 1.2206,-46.48708 37.30987,0.69298 C 477.42467,232.43026 400.19847,156.12219 311.26698,89.143388 l 0.005,-39.76966 c -0.0649,-14.7072 -11.81328,-23.42533 -23.53293,-23.51588 z M 177.8622,115.38418 c -1.91904,0.0704 -4.05106,2.9379 -6.27558,7.02076 L 66.030268,286.18822 c -3.24875,5.10191 -4.95294,9.56751 4.67517,9.36687 l 109.257932,0.0775 c 42.93401,-60.68663 32.81356,-97.37008 2.39882,-172.81478 -1.27894,-5.38034 -2.80671,-7.49575 -4.49999,-7.43365 z"></path></svg>
              
              </a></li>
         
          </ul>
          
        </div>
    </section>
  )
}

export default Navigation