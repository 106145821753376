import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { connect } from './redux/blockchain/blockchainActions'
import { fetchData } from './redux/data/dataActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import * as s from './styles/globalStyles'
import styled from 'styled-components'
import './fonts/Koulen-Regular.ttf'
import './fonts/NotoSans-Black.ttf'
import './fonts/fonts.css'
import { generateMerkleProof } from './whitelist/merkle-tree'
import Navigation from './nav/nav.js';


const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input

export const StyledButton = styled.button`
  padding: 15px;
  border-radius: 10px;
  border: none;
  background-color: var(--button-color);
  padding: 15px;
  font-weight: bold;
  color: var(--button-text);
  width: 135px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--button-color);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--button-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`

const StyledImg = styled.img`
  background-color: var(--accent);
  height: 400px;
  float: left;
  @media (min-width: 900px) {
    height: 350px;
  }
  @media (min-width: 1000px) {
    height: 400px;
  }
  @media (min-width: 2000px) {
    height: 550px;
  }
  @media (min-width: 3540px) {
    height: 750px;
  }

  transition: width 0.5s;
`

export const StyledLink = styled.a`
  color: var(--scanlink);
  text-decoration: none;
`


function App() {
  let [over, setOver] = React.useState(false);

  let titlestyle = {
    color: '',

  }

  const dispatch = useDispatch()
  const blockchain = useSelector((state) => state.blockchain)
  const data = useSelector((state) => state.data)
  const [claimingNft, setClaimingNft] = useState(false)
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`)
  const [mintAmount, setMintAmount] = useState(1)
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: '',
    SCAN_LINK: '',
    NETWORK: {
      NAME: '',
      SYMBOL: '',
      ID: 0,
    },
    NFT_NAME: '',
    SYMBOL: '',
    GAS_LIMIT: 0,
    MARKETPLACE: '',
    MARKETPLACE_LINK: '',
  })

  const claimNFTs = () => {
    let cost = (data.cost)
    let gasLimit = CONFIG.GAS_LIMIT
    let totalCostWei = String((data.price) * mintAmount)
    let totalGasLimit = String(gasLimit)
    console.log('Cost: ', totalCostWei)
    console.log('Gas limit: ', totalGasLimit)
    console.log(cost)
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`)
    setClaimingNft(true)
    const merkleProof = generateMerkleProof(blockchain.account);
    blockchain.smartContract.methods
      .WlMint(mintAmount, merkleProof)
      .send({
        gasLimit: String(totalGasLimit),
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once('error', (err) => {
        console.log(err)
        setFeedback('Sorry, something went wrong please try again later.')
        setClaimingNft(false)
      })
      .then((receipt) => {
        console.log(receipt)
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit ${CONFIG.MARKETPLACE} to view it.`,
        )
        setClaimingNft(false)
        dispatch(fetchData(blockchain.account))
      })
  }

  const PublicclaimNFTs = () => {
    let cost = (data.publicPrice)
    let gasLimit = CONFIG.GAS_LIMIT
    let totalCostWei = String((data.publicPrice) * mintAmount)
    let totalGasLimit = String(gasLimit)
    console.log('Cost: ', totalCostWei)
    console.log('Gas limit: ', totalGasLimit)
    console.log(cost)
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`)
    setClaimingNft(true)
    blockchain.smartContract.methods
      .PublicMint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once('error', (err) => {
        console.log(err)
        setFeedback('Sorry, something went wrong please try again later.')
        setClaimingNft(false)
      })
      .then((receipt) => {
        console.log(receipt)
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit ${CONFIG.MARKETPLACE} to view it.`,
        )
        setClaimingNft(false)
        dispatch(fetchData(blockchain.account))
      })
  }

  const USDCclaimNFTs = () => {
    let gasLimit = CONFIG.GAS_LIMIT
    let totalGasLimit = String(gasLimit)
    console.log('Gas limit: ', totalGasLimit)
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`)
    setClaimingNft(true)
    const merkleProof = generateMerkleProof(blockchain.account);
    blockchain.smartContract.methods
      .WlMintWithUSDC(mintAmount, merkleProof)
      .send({
        gasLimit: String(totalGasLimit),
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      })
      .once('error', (err) => {
        console.log(err)
        setFeedback('Sorry, something went wrong please try again later.')
        setClaimingNft(false)
      })
      .then((receipt) => {
        console.log(receipt)
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit ${CONFIG.MARKETPLACE} to view it.`,
        )
        setClaimingNft(false)
        dispatch(fetchData(blockchain.account))
      })
  }

  const PublicUSDCclaimNFTs = () => {
    let gasLimit = CONFIG.GAS_LIMIT
    let totalGasLimit = String(gasLimit)
    console.log('Gas limit: ', totalGasLimit)
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`)
    setClaimingNft(true)
    blockchain.smartContract.methods
      .PublicMintWithUSDC(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      })
      .once('error', (err) => {
        console.log(err)
        setFeedback('Sorry, something went wrong please try again later.')
        setClaimingNft(false)
      })
      .then((receipt) => {
        console.log(receipt)
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit ${CONFIG.MARKETPLACE} to view it.`,
        )
        setClaimingNft(false)
        dispatch(fetchData(blockchain.account))
      })
  }

  const ApproveUSDC = () => {
    let gasLimit = 90000
    let totalGasLimit = String(gasLimit)
    console.log('Gas limit: ', totalGasLimit)
    let totalCostUSDC = String((data.usdcprice) * mintAmount)
    console.log(totalCostUSDC)
    setFeedback(`Approving USDC`)
    blockchain.usdcContract.methods
      .approve((CONFIG.CONTRACT_ADDRESS), totalCostUSDC)
      .send({
        gasLimit: String(totalGasLimit),
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
        to: CONFIG.USDC_ADDRESS,
        from: blockchain.account,
      })
      .once('error', (err) => {
        console.log(err)
        setFeedback('Sorry, something went wrong please try again later.')
      })
      .then((receipt) => {
        console.log(receipt)
        setFeedback(
          `USDC Approved, Now u can mint with USDC`,
        )
        dispatch(fetchData(blockchain.account))
      })
  }



  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1
    if (newMintAmount < 1) {
      newMintAmount = 1
    }
    setMintAmount(newMintAmount)
  }
  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1
    if (newMintAmount > Number(data.wlmaxMintAmountPerTx)) {
      newMintAmount = Number(data.wlmaxMintAmountPerTx)
    }
    setMintAmount(newMintAmount)
  }

  const getData = () => {
    if (blockchain.account !== '' && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account))
    }
  }

  const getConfig = async () => {
    const configResponse = await fetch('/config/config.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    const config = await configResponse.json()
    SET_CONFIG(config)
  }

  useEffect(() => {
    getConfig()
  }, [])

  useEffect(() => {
    getData((data.cost))
  }, [blockchain.account])

  var ethcost = (data.price) / 1000000000000000000;
  var usdccost = (data.usdcprice) / 1000000;

  var ethcost2 = (data.publicPrice) / 1000000000000000000;
  var usdccost2 = (data.publicUSDCPrice) / 1000000;

  var priceShow;
  if ((data.saleStatus) == true) {
    priceShow = "For Whitelist: each" + " " + CONFIG.NFT_NAME + " " + "costs" + " " + ethcost + " " + CONFIG.NETWORK.SYMBOL + " " + "or" + " " + usdccost + " " + "USDC"
  }
  else {
    priceShow = "Price: " + " " + ethcost2 + " " + CONFIG.NETWORK.SYMBOL + " " + "or" + " " + usdccost2 + " " + "USDC"
  }

   
// Date
var countDownDate = new Date("Feb 14, 2023 14:00:00 GMT-0500").getTime();

// Update the count down every 1 second
var x = setInterval(function() {

  // today's date and time
  var now = new Date().getTime();
    
  // Find the distance between now and the count down date
  var distance = countDownDate - now;
    
  // Time calculations for days, hours, minutes and seconds
  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    
  document.getElementById("timer").innerHTML = "ENDS IN " + hours + "h "
  + minutes + "m " + seconds + "s ";
    
  // If the count down is over 
  if (distance < 0) {
    clearInterval(x);
    document.getElementById("timer").innerHTML = "SNAPSHOT TAKEN";
  }
}, 1000);  


  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={'center'}
        style={{ padding: 24, backgroundColor: 'var(--primary)' }}
      >
        <Navigation />
        <ResponsiveWrapper flex={1} style={{ padding: 24, margin: 50 }} test>
          <s.Container flex={1} jc={'center'} ai={'center'}>
            <StyledImg alt={'example'} src={'/config/images/example.gif'}>

            </StyledImg>
          </s.Container>
          <s.Container2
            flex={1}
            jc={'center'}
            ai={'left'}
            style={{
              backgroundColor: 'var(--background)',
              padding: 24,
            }}
          >
            {' '}
            <s.SpacerSmall />
            {Number(data.totalSupply) >= data.wlsupplyLimit ? (
              <>
                <s.TextTitle
                  style={{ textAlign: 'left', fontFamily: 'KoulenRegular', color: 'var(--accent-text)', fontSize: 50 }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: 'left', fontFamily: 'KoulenRegular', color: 'var(--accent-text)', fontSize: 25 }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={'_blank'} style={{ fontFamily: 'KoulenRegular', fontSize: 35 }} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.SpacerSmall />
                {blockchain.account === '' ||
                  blockchain.smartContract === null ? (
                  <s.Container ai={'left'} jc={'left'}>
                    <s.TextTitle
                      style={{
                        position: 'left',
                        textAlign: 'left',
                        fontSize: 45,
                        fontWeight: 'bold',
                        fontFamily: 'KoulenRegular',
                        color: 'var(--accent-text)',
                      }}
                    >
                     NFT Yearbook Mint Pass Sale Closed
                    </s.TextTitle>
                    
                    
                    <s.TextTitle
                      style={{
                        position: 'left',
                        textAlign: 'left',
                        fontSize: 35,
                        fontWeight: 'bold',
                        fontFamily: 'KoulenRegular',
                        color: 'var(--accent-text)',
                      }}
                    > Public Sale on OpenSea: 0.125 ETH, Feb 16th
                    </s.TextTitle>
                    <s.TextTitle
                      style={{
                        
                        position: 'left',
                        textAlign: 'left',
                        fontSize: 20,
                        fontWeight: 'normal',
                        color: 'var(--accent-text)',
                      }}
                    >
                      The NFT Yearbook Mint Pass is your ticket to owning one 3D Digital Readable yearbook and a Physical Coffee Table Yearbook sent to your door. There are two mint pass cover designs, graciously created by two amazing artists - Amu and Soulo.
                    </s.TextTitle>
                    <s.SpacerSmall />
                    <s.TextTitle
                      style={{
                        
                        position: 'left',
                        textAlign: 'left',
                        fontSize: 20,
                        fontWeight: 'normal',
                        color: 'var(--accent-text)',
                      }}
                    >
                      A Mint Pass holder snapshot will be taken on Feb 14th. Mint pass holders will claim the NFTYB NFT on Opensea for FREE, 24h before public, starting Feb 15th, 12 EST. Claiming will end after 24h.
                    </s.TextTitle>
                    <s.SpacerSmall />
                    <s.TextTitle
                      style={{
                        
                        position: 'left',
                        textAlign: 'left',
                        fontSize: 20,
                        fontWeight: 'normal',
                        color: 'var(--accent-text)',
                      }}
                    >
                      The NFT includes a future Physical claim. 
                      NO taxes or shipping to be added.
                    </s.TextTitle>
                    
                    <s.SpacerSmall />
                    <s.TextTitle
                      style={{
                       
                        position: 'left',
                        textAlign: 'left',
                        fontSize: 20,
                        fontWeight: 'normal',
                        color: 'var(--accent-text)',
                      }}
                    >
                      The book features artists, creators, founders, collectors, and the community! We've interviewed over 500 participants and reached over 750h of recorded interviews in the last 8 months. Our labor of love for this space ❤️ It showcases everything that we love about web3 and crypto-art and we have loved creating it with you. 
                    </s.TextTitle>
                    
                    <s.TextTitle
                      style={{
                        fontFamily: 'KoulenRegular',
                        position: 'left',
                        textAlign: 'justify',
                        fontSize: 25,
                        fontWeight: 'normal',
                        color: 'var(--accent-text)',
                      }}
                    >
                      -----
                    </s.TextTitle>
                    <s.TextDescription
                      style={{

                        textAlign: 'left',
                        color: 'var(--scanlink)',
                        fontSize: 20,
                        fontWeight: 'bold',
                        fontFamily: 'KoulenRegular',
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network to mint
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault()
                        dispatch(connect())
                        getData()
                      }}
                    >
                      CLOSED
                    </StyledButton>
                    {blockchain.errorMsg !== '' ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: 'left',
                            color: 'var(--accent-text)',
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextTitle
                      style={{
                        position: 'left',
                        textAlign: 'left',
                        fontSize: 45,
                        fontWeight: 'bold',
                        fontFamily: 'KoulenRegular',
                        color: 'var(--accent-text)',
                      }}
                    >
                      Mint your NFT Yearbook Mint Pass
                    </s.TextTitle>
                    <s.TextTitle
                      style={{
                        position: 'left',
                        textAlign: 'left',
                        fontSize: 5,
                        fontWeight: 'bold',
                        fontFamily: 'KoulenRegular',
                        color: '#000000',
                      }}
                    >
                      Currently Minted - {data.totalSupply}
                    </s.TextTitle>
                    <s.TextTitle
                      style={{
                        fontFamily: 'KoulenRegular',
                        position: 'left',
                        textAlign: 'left',
                        fontSize: 5,
                        fontWeight: 'bold',
                        color: '#000000',
                      }}
                    >
                      Max Supply that can be minted is - {data.wlsupplyLimit}
                    </s.TextTitle>
                    <s.TextDescription
                      style={{
                        textAlign: 'left',
                        color: 'var(--primary-text)',
                      }}
                    >
                      
                    </s.TextDescription>

                    <s.TextTitle
                    id="timer"
                      style={{
                        position: 'left',
                        textAlign: 'left',
                        fontSize: 35,
                        fontWeight: 'bold',
                        fontFamily: 'KoulenRegular',
                        color: 'var(--accent-text)',
                      }}
                    >
                     
                    </s.TextTitle>

                    <s.TextDescription
                      style={{
                        textAlign: 'left',
                        color: 'var(--accent-text)',
                        fontFamily: 'KoulenRegular'
                      }}
                    >

                      <s.TextTitle
                        style={{ textAlign: 'left', fontSize: 25, fontFamily: 'KoulenRegular', color: 'var(--accent-text)' }}
                      >
                        You have the option of minting your NFT Yearbook(s) in USDC (fixed price) or in ETH (USDC equivalent). This includes the 3D Yearbook and the physical coffee table Yearbook
                      </s.TextTitle>
                      <s.SpacerXSmall />
                      <s.TextTitle
                        style={{ textAlign: 'left', fontSize: 25, fontFamily: 'KoulenRegular', color: 'var(--accent-text)' }}
                      >
                        {priceShow}
                      </s.TextTitle>

                      <s.SpacerXSmall />
                      <s.TextDescription
                        style={{ textAlign: 'left', fontFamily: 'KoulenRegular', color: 'var(--accent-text)' }}
                      >
                        -----
                      </s.TextDescription>
                      
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container ai={'left'} jc={'left'} fd={'row'}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4, fontFamily: 'KoulenRegular' }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault()
                          decrementMintAmount()
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: 'left',
                          color: 'var(--accent-text)',
                          fontFamily: 'KoulenRegular'
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault()
                          incrementMintAmount()
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={'left'} jc={'left'} fd={'row'}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault()
                          if ((data.saleStatus) == true) {
                            const merkleProof = generateMerkleProof(blockchain.account);
                            if (merkleProof.length > 0) {
                              if (((Number(data.wlMintCount)) + mintAmount) <= (Number(data.wlmaxLimitPerWallet))) {
                                claimNFTs()
                              }
                              else {
                                setFeedback('Max Mint Limit Reached or Minting more than allowed!')
                              }
                            }
                            else {
                              setFeedback('Not whitelisted!')
                            }
                          }
                          else if ((data.publicSaleStaus) == true) {
                            if (((Number(data.publicMintCount)) + mintAmount) <= (Number(data.publicWalletLimit))) {
                              PublicclaimNFTs()
                            }
                            else {
                              setFeedback('Max Mint Limit Reached or Minting more than allowed!')
                            }
                          }
                          else {
                            setFeedback('Sale Not Active')
                          }
                          getData()
                        }}
                      >
                        {claimingNft ? 'BUSY' : 'BUY with ETH'}
                      </StyledButton>

                      <s.SpacerSmall></s.SpacerSmall>

                      <StyledButton disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault()
                          if ((data.saleStatus) == true) {
                            const merkleProof = generateMerkleProof(blockchain.account);
                            if (merkleProof.length > 0) {
                              if (((Number(data.wlMintCount)) + mintAmount) <= (Number(data.wlmaxLimitPerWallet))) {
                                if (((Number(data.usdcprice)) * mintAmount) <= (Number(data.approvedBalance))) {
                                  USDCclaimNFTs()
                                } else {
                                  setFeedback('Enough USDC not approved! Please approve enough USDC')
                                }
                              }
                              else {
                                setFeedback('Max Mint Limit Reached or Minting more than allowed!')
                              }
                            }
                            else {
                              setFeedback('Not whitelisted!')
                            }
                          }
                          else if ((data.publicSaleStaus) == true) {
                            if (((Number(data.publicMintCount)) + mintAmount) <= (Number(data.publicWalletLimit))) {
                              if (((Number(data.publicUSDCPrice)) * mintAmount) <= (Number(data.approvedBalance))) {
                                PublicUSDCclaimNFTs()
                              } else {
                                setFeedback('Enough USDC not approved! Please approve enough USDC')
                              }
                            }
                            else {
                              setFeedback('Max Mint Limit Reached or Minting more than allowed!')
                            }
                          }
                          else {
                            setFeedback('Sale Not Active')
                          }
                          getData()
                        }}>
                        {claimingNft ? 'BUSY' : 'BUY with USDC'}
                      </StyledButton>

                      <s.SpacerSmall></s.SpacerSmall>
                      <StyledButton disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault()
                          ApproveUSDC()
                          getData()
                        }}
                      >
                        Approve USDC
                      </StyledButton>

                    </s.Container>
                    <s.SpacerSmall></s.SpacerSmall>
                    <s.TextDescription
                      style={{ textAlign: 'left', fontFamily: 'KoulenRegular', color: 'var(--accent-text)' }}
                    >
                      Please note: You will need to approve USDC before minting with USDC
                    </s.TextDescription>
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
          </s.Container2>

        </ResponsiveWrapper>
        <s.SpacerMedium />
        <s.Container jc={'center'} ai={'center'} style={{ width: '70%' }}>
          <s.TextDescription
            href={CONFIG.LINKTREE} target={'_blank'}
            style={{
              textAlign: 'center',
              color: 'var(--accent-text)',
              fontFamily: 'KoulenRegular',
            }}
          >
            <StyledLink href={CONFIG.LINKTREE} target={'_blank'} >
              2022 all rights reserved The NFT Yearbook | Created with <FontAwesomeIcon icon={faHeart} /> by Metaverse Publishing</StyledLink>
          </s.TextDescription>
          <s.SpacerSmall />
        </s.Container>
      </s.Container>
    </s.Screen>
  )
}
export default App
