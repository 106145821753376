const initialState = {
  loading: false,
  totalSupply: 0,
  cost: 0,
  wlsupplyLimit: 1,
  price: 0,
  usdcprice: 0,
  wlMintCount: 0,
  wlmaxLimitPerWallet: 1,
  wlmaxMintAmountPerTx: 1,
  approvedBalance: 0,
  publicWalletLimit: 1,
  publicTxLimit: 1,
  publicMintCount: 0,
  publicPrice: 0,
  publicUSDCPrice: 0,
  publicSupplyLimit: 1,
  saleStatus: false,
  publicSaleStaus: false,
  error: false,
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        totalSupply: action.payload.totalSupply,
        cost: action.payload.price,
        wlsupplyLimit: action.payload.wlsupplyLimit,
        price: action.payload.price,
        usdcprice: action.payload.usdcprice,
        wlMintCount: action.payload.wlMintCount,
        wlmaxLimitPerWallet: action.payload.wlmaxLimitPerWallet,
        saleStatus: action.payload.saleStatus,
        wlmaxMintAmountPerTx: action.payload.wlmaxMintAmountPerTx,
        approvedBalance: action.payload.approvedBalance,
        publicWalletLimit: action.payload.publicWalletLimit,
        publicTxLimit: action.payload.publicTxLimit,
        publicMintCount: action.payload.publicMintCount,
        publicPrice: action.payload.publicPrice,
        publicUSDCPrice: action.payload.publicUSDCPrice,
        publicSupplyLimit: action.payload.publicSupplyLimit,
        publicSaleStaus: action.payload.publicSaleStaus,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
