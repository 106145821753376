// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {

  return async (dispatch) => {
    dispatch(fetchDataRequest());
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const CONFIG = await configResponse.json();
    try {
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();
      let price = await store
        .getState()
        .blockchain.smartContract.methods.wlprice()
        .call();
      let usdcprice = await store
        .getState()
        .blockchain.smartContract.methods.usdcwlprice()
        .call();
      let account = await store
        .getState()
        .blockchain.account;
      let wlMintCount = await store
        .getState()
        .blockchain.smartContract.methods.wlMintCount(account)
        .call();
      let saleStatus = await store
        .getState()
        .blockchain.smartContract.methods.whitelistSale()
        .call();
      let wlsupplyLimit = await store
        .getState()
        .blockchain.smartContract.methods.wlsupplyLimit()
        .call();
      let wlmaxLimitPerWallet = await store
        .getState()
        .blockchain.smartContract.methods.wlmaxLimitPerWallet()
        .call();
      let wlmaxMintAmountPerTx = await store
        .getState()
        .blockchain.smartContract.methods.wlmaxMintAmountPerTx()
        .call();
      let approvedBalance = await store
        .getState()
        .blockchain.usdcContract.methods.allowance(account, (CONFIG.CONTRACT_ADDRESS))
        .call();

      // public
      let publicWalletLimit = await store
        .getState()
        .blockchain.smartContract.methods.maxLimitPerWallet()
        .call();

      let publicTxLimit = await store
        .getState()
        .blockchain.smartContract.methods.maxMintAmountPerTx()
        .call();

      let publicMintCount = await store
        .getState()
        .blockchain.smartContract.methods.publicMintCount(account)
        .call();

      let publicPrice = await store
        .getState()
        .blockchain.smartContract.methods.price()
        .call();

      let publicUSDCPrice = await store
        .getState()
        .blockchain.smartContract.methods.usdcprice()
        .call();

      let publicSupplyLimit = await store
        .getState()
        .blockchain.smartContract.methods.supplyLimit()
        .call();

      let publicSaleStaus = await store 
        .getState()
        .blockchain.smartContract.methods.publicSale()
        .call();


      dispatch(
        fetchDataSuccess({
          totalSupply,
          wlsupplyLimit,
          price,
          usdcprice,
          wlMintCount,
          wlmaxLimitPerWallet,
          saleStatus,
          wlmaxMintAmountPerTx,
          approvedBalance,
          publicWalletLimit,
          publicTxLimit,
          publicMintCount,
          publicPrice,
          publicUSDCPrice,
          publicSupplyLimit,
          publicSaleStaus
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
